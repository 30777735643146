import React from 'react';
import { Input, Button, Form } from 'antd';
import { Link, useNavigate } from 'react-router-dom'; // Assuming you're using React Router for navigation
import { AppDispatch } from 'app-redux/store';
import { useDispatch } from 'react-redux';
import { message } from 'antd';

import { loginUser } from 'app-redux/slices/auth';

import styles from './Home.module.css';

interface FormValues {
    email: string,
    password: string
}

const Home: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const onFinish = (values: FormValues) => {
        
        console.log('Received values:', values);
        try{
            dispatch(loginUser({ email: values.email, password: values.password })).unwrap().then(() => {
                navigate("/");
                window.location.reload()
            }).catch((error) => {
                console.log(error);
                message.error(error.message)
            });
        }
        catch (errorInfo) {
            console.log('Validation failed:', errorInfo);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.loginBox}>
                <h1>Đăng nhập</h1>
                <Form onFinish={onFinish}>
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Vui lòng nhập email!' }]}
                    >
                        <Input placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
                    >
                        <Input.Password placeholder="Mật khẩu" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Đăng nhập
                        </Button>
                    </Form.Item>
                </Form>
                <div className={styles.registerLink}>
                    <p>
                        Bạn chưa có tài khoản? <Link to="/register">Đăng ký ngay</Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Home;
