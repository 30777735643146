
import { Space, Table, Row, Button, Modal, Form, Input, Popconfirm, Select, Typography } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { connect, useDispatch } from "react-redux";
import { addMember, getMembers, removeMember, updateMember, setCurrentPage } from "app-redux/slices/member"
import { getTutorClasses } from "app-redux/slices/tutorClass"
import React, { useEffect, useState } from "react";
import { showSuccessMessage, showErrorMessage } from "helper/Helper"
import { useSelector } from "react-redux";

import styles from './Member.module.css'

const { Text } = Typography;
const pageSize = 30
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}
const dateFormat = "YYYY-MM-DD";

const ClassMember = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [classList, setClassList] = useState([]);
  const [tutorClassId, setTutorClassId] = useState(null);
  const dispatch = useDispatch();
  const { member, tutorClass } = useSelector(state => state)
  const [isAddMode, setIsAddMode] = useState(true)
  //Member này hơi đặc biệt, có lúc được add thêm các thuộc tính của Attend. 
  //Cụ thể: modifiedMember = {...item.member, startDate:item.startDate, endDate:item.endDate, discount:item.discount, attendId:item.id}
  const [editingMember, setEditingAttend] = useState()

  const [form] = Form.useForm();
  const [formTitle, setFormTitle] = useState("Thêm học viên")

  useEffect(() => {
    loadData(null);
    dispatch(getTutorClasses());

  }, [])

  useEffect(() => {
    console.log(member.members)
  }, [member])

  useEffect(() => {
    console.log("tutor Class 1111")
    console.log(tutorClass)
    const tempClassList = tutorClass.tutorClasses.map((item, index) => {
      return {
        value: index,
        label: item.name
      }
    })
    setClassList(tempClassList)

    console.log(tempClassList)

  }, [tutorClass])

  useEffect(() => {
    if (tutorClassId) {
      loadData(tutorClassId)
    }
    else {
      loadData()
    }
  }, [member.currentPage])

  const showModal = () => {
    setIsModalOpen(true);
  };

  const onAddClick = () => {
    setIsAddMode(true)
    showModal()
    form.resetFields()
    setFormTitle("Thêm học viên")
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setIsModal2Open(false)
  };

  const onFinish = (values) => {
    console.log(values);

    if (isAddMode) {
      dispatch(addMember(values)).unwrap().then((_) => {
        dispatch(getMembers());
        showSuccessMessage("Thêm thành công")
      })
        .catch((e) => {
          showErrorMessage(e.message)
        })
    }
    else {
      let payload = { ...values, memberId: editingMember.id }
      dispatch(updateMember(payload)).unwrap().then((_) => {
        dispatch(getMembers());
        showSuccessMessage("Sửa thành công")
      })
        .catch((e) => {
          showErrorMessage(e.message)
        })
    }

    setIsModalOpen(false);
  };

  const onChangePage = (pagination) => {
    console.log(pagination)
    dispatch(setCurrentPage(pagination.current))
  }

  const onEditClick = (index) => {
    console.log(index);
    setIsAddMode(false)
    setFormTitle("Sửa thông tin học viên")
    const tempMember = member.members[index]
    console.log(tempMember)
    form.setFieldsValue(tempMember)
    setEditingAttend(tempMember)

    showModal()
  }

  const onDeleteClick = async (index) => {
    let payload = {
      memberId: member.members[index].id
    }

    dispatch(removeMember(payload)).unwrap().then((_) => {
      showSuccessMessage("Xoá thành công")
      dispatch(getMembers());
    })
      .catch((e) => {
        showErrorMessage(e.message)
      });

  }

  const onChangeSelect = (value) => {
    console.log(value)
    const classId = tutorClass.tutorClasses[value].id
    setTutorClassId(classId)
    dispatch(setCurrentPage(1)) //Reset to page 1
    loadData(classId)
  }

  const loadData = (classId = null) => {
    let params = `page=${member.currentPage}&take=${pageSize}`
    if (classId !== null && classId > 0) {
      params = `classId=${classId}&page=${member.currentPage}&take=${pageSize}`
    }

    dispatch(getMembers(params));
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'numberIndex',
      width: 60,
      render: (_, { numberIndex, attendStopped }, index) => (attendStopped ? <Text disabled>{(member.currentPage - 1) * pageSize + index + 1}</Text> : <Text default>{(member.currentPage - 1) * pageSize + index + 1}</Text>)
    },
    {
      title: 'Họ tên',
      width: 200,
      dataIndex: 'name',
      render: (_, { name, attendStopped }) => (attendStopped ? <Text disabled>{name}</Text> : <Text default>{name}</Text>)
    },
    {
      title: 'Phụ huynh',
      width: 200,
      dataIndex: 'parent',
      render: (_, { parent, attendStopped }) => (attendStopped ? <Text disabled>{parent}</Text> : <Text default>{parent}</Text>)
    },
    {
      title: 'Mobile',
      width: 150,
      dataIndex: 'mobile',
      render: (_, { mobile, attendStopped }) => (attendStopped ? <Text disabled>{mobile}</Text> : <Text default>{mobile}</Text>)
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      render: (_, { note, attendStopped }) => (attendStopped ? <Text disabled>{note}</Text> : <Text default>{note}</Text>)
    },
    {
      title: 'Ngày học',
      dataIndex: 'startDate',
      render: (_, { startDate, attendStopped }) => (attendStopped ? <Text disabled>{startDate}</Text> : <Text default>{startDate}</Text>)
    },
    {
      title: 'Ngày kết thúc',
      dataIndex: 'endDate',
      render: (_, { endDate, attendStopped }) => (attendStopped ? <Text disabled>{endDate}</Text> : <Text default>{endDate}</Text>)
    },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'center',
      render: (_, record, index) => (
        <Space size="middle">
          <a onClick={() => onEditClick(index)}>
            <EditOutlined />
          </a>
          <Popconfirm
            title="Chắc chắn xoá học viên này?"
            onConfirm={() => onDeleteClick(index)}
            okText="Yes"
            cancelText="No"
          >
            <a href="#">
              <DeleteOutlined />
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      
      <Modal title={formTitle} open={isModalOpen}
        onOk={form.submit}
        onCancel={handleCancel}>
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
          <Form.Item name="name" label="Tên học viên" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="parent" label="Phụ huynh" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="mobile" label="Số ĐT Phụ huynh" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="note" label="Ghi chú" rules={[{ required: false }]}>
            <Input />
          </Form.Item>
        </Form>
        <span style={{ fontStyle: "italic" }}>Dấu (<span style={{ color: "red" }}>*</span>) là bắt buộc nhập dữ liệu </span>
      </Modal>
      <h1 className={styles.mainTitle}> Quản lý học viên </h1>
      <Row justify="end" style={{paddingBottom: 10}}>
        <Space>
          <Select
            showSearch
            style={{ width: 200 }}
            onChange={onChangeSelect}
            placeholder="Lọc theo lớp"
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').toUpperCase().includes(input.toLocaleUpperCase())}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={classList}
          />
          <Button type="primary" onClick={onAddClick}>Thêm học viên</Button>
        </Space>
      </Row>
      <Table columns={columns} dataSource={tutorClassId === null ? member.members:member.classMembers} scroll={{ x: 1000 }} size='small' pagination={{ pageSize: pageSize, total: Math.ceil( tutorClassId === null ? member.totalRecords:member.totalRecordsClassMembers) }} onChange={onChangePage} />

    </div>
  )
}

export default connect(null, { addMember })(ClassMember);