import { Space, Table, Row, Button, Modal, Form, Input, InputNumber, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch } from "react-redux";
import { addTutorClass, updateTutorClass, getTutorClasses, removeTutorClass } from "app-redux/slices/tutorClass"
import React, { useEffect, useState } from "react";
import { showSuccessMessage, showErrorMessage } from "helper/Helper"

import { useSelector } from "react-redux";

import styles from './TutorClass.module.css';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const TutorClass = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tutorClass } = useSelector(state => state)
  const [tutorClassIndex, setTutorClassIndex] = useState(0)
  const [formTitle, setFormTitle] = useState("Thêm lớp")
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddMode, setIsAddMode] = useState(true)
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getTutorClasses());
  }, [])

  const showModal = () => {
    setIsModalOpen(true);
  };

  const onAddClick = () => {
    showModal()
    setIsAddMode(true)
    form.resetFields()
    setFormTitle("Thêm lớp")
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = (values) => {
    if (isAddMode) {
      dispatch(addTutorClass(values)).unwrap().then((_) => {
        dispatch(getTutorClasses());
        showSuccessMessage("Thêm thành công")
      })
        .catch((e) => {
          showErrorMessage(e.message)
        })
    }
    else {
      const payloadData = { ...values, classId: tutorClass.tutorClasses[tutorClassIndex].id };

      dispatch(updateTutorClass(payloadData)).unwrap().then((_) => {
        dispatch(getTutorClasses());
        showSuccessMessage("Sửa thành công")
      })
        .catch((e) => {
          showErrorMessage(e.message)
        })
    }
    setIsModalOpen(false);
  };

  const onEditClick = (index) => {
    setIsAddMode(false)
    setFormTitle("Sửa thông tin lớp")
    setTutorClassIndex(index)
    form.setFieldsValue(tutorClass.tutorClasses[index])
    showModal()
  }

  const onDetailClick = (index) => {
    const classId = tutorClass.tutorClasses[index].id;
    navigate(`/class-detail/${classId}`);
  }

  const onDeleteClick = async (index) => {
    console.log(index);
    let payload = {
      classId: tutorClass.tutorClasses[index].id
    }

    dispatch(removeTutorClass(payload)).unwrap().then((_) => {
      showSuccessMessage("Xoá thành công")
    })
      .catch((e) => {
        showErrorMessage(e.message)
      })
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'numberIndex',
      width: 60
    },
    {
      title: 'Tên lớp',
      dataIndex: 'name',
      width: 250
    },
    {
      title: 'Sĩ số',
      dataIndex: 'numberOfAttends',
      width: 50
    },
    {
      title: 'Học phí',
      dataIndex: 'fee',
      width: 150,
      align: 'right',
      render: (_, { fee }) => (<>{fee.toLocaleString(undefined, { maximumFractionDigits: 2 })}</>)
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note'
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      width:120
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 100,
      render: (_, record, index) => (
        <Space size="middle">
          <a onClick={() => onEditClick(index)}>
            <EditOutlined />
          </a>
          <Popconfirm
            title="Chắc chắn xoá dữ liệu này?"
            onConfirm={() => onDeleteClick(index)}
            okText="Yes"
            cancelText="No"
          >
            <a href="#">
              <DeleteOutlined />
            </a>
          </Popconfirm>
          <a onClick={() => onDetailClick(index)}>
            <EyeOutlined />
          </a>
        </Space>
      ),
    },
  ];

  const onChange = (value) => {
    console.log('changed', value);
  };

  return (
    <div>
      <Modal title={formTitle} open={isModalOpen} onOk={form.submit} onCancel={handleCancel}>
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
          <Form.Item name="name" label="Tên lớp" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="fee" label="Học phí" rules={[{ required: true }]}>
            <InputNumber min={20000} onChange={onChange} />
          </Form.Item>
          <Form.Item name="note" label="Ghi chú" rules={[{ required: false }]}>
            <Input />
          </Form.Item>
        </Form>
        <span style={{ fontStyle: "italic" }}>Dấu (<span style={{ color: "red" }}>*</span>) là bắt buộc nhập dữ liệu </span>
      </Modal>
      <h1 className={styles.mainTitle}> Quản lý lớp </h1>
      <Row justify="end" style={{paddingBottom: 10}}>
        <Space>
          <Button type="primary" onClick={onAddClick}>Thêm lớp</Button>
        </Space>
      </Row>
      <Table columns={columns} dataSource={tutorClass.tutorClasses} pagination={{ pageSize: 100 }} size='small' />
    </div>
  )
}

export default connect(null, { addTutorClass })(TutorClass);
