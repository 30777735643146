import { Tabs, Switch, Space, Table, Row, Col, Button, Modal, Form, Input, Popconfirm, Select, Typography } from 'antd';
import { DatePicker, InputNumber } from 'antd';
import type { MenuProps } from 'antd';
import { useParams } from 'react-router-dom';
import { EditOutlined, DeleteOutlined} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from "react";
import moment from 'moment';

import { AppDispatch, RootState } from 'app-redux/store';
import { showSuccessMessage, showErrorMessage, formatDate } from "helper/Helper"
import { removeMemberFromClass } from "app-redux/slices/tutorClass"
import { getMembers, updateMember, setCurrentPage } from "app-redux/slices/member"
import styles from './ClassMember.module.css';
import { Member } from 'types/member/Member';
import AddMemberToClass from '../AddMemberToClass/AddMemberToClass';

const { Text } = Typography;
const pageSize = 30
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const ClassMember: React.FC = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const { member } = useSelector((state: RootState) => state)

  //Member này hơi đặc biệt, có lúc được add thêm các thuộc tính của Attend. 
  //Cụ thể: modifiedMember = {...item.member, startDate:item.startDate, endDate:item.endDate, discount:item.discount, attendId:item.id}
  const [editingMember, setEditingMember] = useState<Member>()

  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [formTitle, setFormTitle] = useState("Thêm học viên")
  const { classId } = useParams();

  const dateFormat = "YYYY-MM-DD";

  useEffect(() => {
    loadData(classId)
  }, [member.currentPage])

  const showModal = () => {
    setIsModalOpen(true);
  };

  const onAddClick = () => {
    setIsModal2Open(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setIsModal2Open(false)
  };

  const onFinish = (values) => {
    const startDate = new Date(values.startDate)
    const endDate = new Date(values.endDate)
    const payload = { ...values, memberId: editingMember.id, attendId: editingMember.attendId, startDate: formatDate(startDate), endDate: formatDate(endDate) }
    dispatch(updateMember(payload)).unwrap().then((_) => {
      loadData(classId)
      showSuccessMessage("Sửa thành công")
    })
      .catch((e) => {
        showErrorMessage(e.message)
      })
    setIsModalOpen(false);
  };

  const onChangePage = (pagination) => {
    dispatch(setCurrentPage(pagination.current))
  }

  const onEditClick = (index) => {
    setFormTitle("Sửa thông tin học viên")
    const tempMember = member.classMembers[index]
    const temp = { ...tempMember, startDate: moment(tempMember.startDate, dateFormat), endDate: moment(tempMember.endDate, dateFormat) }
    form.setFieldsValue(temp)
    setEditingMember(tempMember)
    showModal()
  }

  const onDeleteMemberFromClassClick = async (index) => {
    const payload = {
      memberId: member.classMembers[index].id,
      classId: classId
    }

    dispatch(removeMemberFromClass(payload)).unwrap().then((_) => {
      showSuccessMessage("Xoá thành công")
      const params = `classId=${classId}`
      dispatch(getMembers(params));
    })
      .catch((e) => {
        showErrorMessage(e.message)
      });
  }

  const onFinishAddMemberToClass = () => {
    loadData(classId);
  }

  const loadData = (classId = null) => {
    let params = `page=${member.currentPage}&take=${pageSize}`
    if (classId !== null && classId > 0) {
      params = `classId=${classId}&page=${member.currentPage}&take=${pageSize}`
    }

    dispatch(getMembers(params));
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'numberIndex',
      width: '50px',
      render: (_, { numberIndex, attendStopped }, index) => (attendStopped ? <Text disabled>{(member.currentPage - 1) * pageSize + index + 1}</Text> : <Text>{(member.currentPage - 1) * pageSize + index + 1}</Text>)
    },
    {
      title: 'Họ tên',
      dataIndex: 'name',
      render: (_, { name, attendStopped }) => (attendStopped ? <Text disabled>{name}</Text> : <Text>{name}</Text>)
    },
    {
      title: 'Phụ huynh',
      dataIndex: 'parent',
      render: (_, { parent, attendStopped }) => (attendStopped ? <Text disabled>{parent}</Text> : <Text>{parent}</Text>)
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      render: (_, { mobile, attendStopped }) => (attendStopped ? <Text disabled>{mobile}</Text> : <Text>{mobile}</Text>)
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      render: (_, { note, attendStopped }) => (attendStopped ? <Text disabled>{note}</Text> : <Text>{note}</Text>)
    },
    {
      title: 'Giảm giá',
      dataIndex: 'discount',
      render: (_, { discount, attendStopped }) => (attendStopped ? <Text disabled>{discount != null ? discount.toLocaleString(undefined, { maximumFractionDigits: 2 }) : ""}</Text> : <Text>{discount != null ? discount.toLocaleString(undefined, { maximumFractionDigits: 2 }) : ""}</Text>)
    },
    {
      title: 'Ngày học',
      dataIndex: 'startDate',
      render: (_, { startDate, attendStopped }) => (attendStopped ? <Text disabled>{startDate}</Text> : <Text>{startDate}</Text>)
    },
    {
      title: 'Ngày kết thúc',
      dataIndex: 'endDate',
      render: (_, { endDate, attendStopped }) => (attendStopped ? <Text disabled>{endDate}</Text> : <Text>{endDate}</Text>)
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record, index) => (
        <Space size="middle">
          <a onClick={() => onEditClick(index)}><EditOutlined /></a>
          <Popconfirm
            title="Chắc chắn xoá học viên này khỏi lớp. Toàn bộ dữ liệu học của học viên sẽ bị xoá ?"
            onConfirm={() => onDeleteMemberFromClassClick(index)}
            okText="Yes"
            cancelText="No"
          >
            <a href="#"><DeleteOutlined /></a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Modal title={"Thêm học sinh vào lớp"} open={isModal2Open} onOk={form3.submit} onCancel={handleCancel}>
        <AddMemberToClass form={form3}  onFinishAddMemberToClass = {onFinishAddMemberToClass} classId={Number(classId)} ></AddMemberToClass>
      </Modal>

      <Modal title={formTitle}
        open={isModalOpen}
        width={900}
        onOk={form.submit}
        onCancel={handleCancel}>
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
          <div className={styles.formBox}>
            <div className={styles.formBoxTitle}>Thông tin chung</div>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="name" label="Tên học viên" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="parent" label="Phụ huynh" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="mobile" label="Số ĐT Phụ huynh" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="note" label="Ghi chú" rules={[{ required: false }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className={styles.formBox} style={{ marginTop: 40 }}>
            <div className={styles.formBoxTitle}>Tham gia lớp</div>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="startDate" label="Ngày bắt đầu" rules={[{ required: true }]}>
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="endDate" label="Ngày kết thúc" rules={[{ required: true }]}>
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="discount" label="Giảm giá" rules={[{ required: false }]}>
                  <InputNumber min={0} />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
        <span style={{ fontStyle: "italic" }}>Dấu (<span style={{ color: "red" }}>*</span>) là bắt buộc nhập dữ liệu </span>
      </Modal>
      <h1 className={styles.mainTitle}> Quản lý học viên </h1>
      <Row justify="end" style={{paddingBottom: 10}}>
        <Space>
          <Button type="primary" onClick={onAddClick}>Thêm học viên</Button>
        </Space>
      </Row>
      <Table columns={columns} dataSource={member.classMembers} scroll={{ x: 1000 }} size='small' pagination={{ pageSize: pageSize, total: Math.ceil(member.totalRecordsClassMembers) }} onChange={onChangePage} />

    </div>
  )
}

export default ClassMember;
