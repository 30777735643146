import { Tabs, Switch } from 'antd';
import { useParams } from 'react-router-dom';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { connect, useDispatch } from "react-redux";
import { getTutorClassDetail } from "app-redux/slices/tutorClass"
import React, { useEffect, useState } from "react";
import { AppDispatch, RootState } from 'app-redux/store';

import { useSelector } from "react-redux";
import styles from './ClassDetail.module.css';
import ClassMember from '../ClassMember/ClassMember';


const ClassDetail: React.FC = (props) => {
  const dispatch: AppDispatch = useDispatch();
  const { tutorClass } = useSelector((state: RootState) => state)
  const { classId } = useParams();

  useEffect(() => {
    console.log(classId);
    dispatch(getTutorClassDetail(`classId=${classId}`));
  }, [])

  const onChange = (checked: boolean) => {
    console.log(`switch to ${checked}`);
  };

  return (
    <div>
      <div className={styles.classNameSection}>
         <span> <b>Lớp:</b> {tutorClass.classDetail !== null ? tutorClass.classDetail.name : ""} </span>
         <span>Trạng thái hoạt động <Switch defaultChecked onChange={onChange} /> </span>
      </div>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Danh sách học viên" key="1">
          <ClassMember></ClassMember>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Bài tập" key="2">
          Danh sách bài tập (coming soon)
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

export default ClassDetail;
