import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  SettingOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, Dropdown, Avatar } from 'antd';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './MainLayout.module.scss';

import ChangePassword from '../pages/auth/changePassword/ChangePassword';

const { Header, Content, Footer, Sider } = Layout;


function getItem(label, key, icon?, children?) {
  return {
    key,
    icon,
    children,
    label
  };
}

const items = [
  getItem((
    <Link to="/">Quản lý lớp</Link>
  ), '1', <PieChartOutlined />),
  getItem((
    <Link to="/member">Quản lý học viên</Link>
  ), '2', <TeamOutlined />),
  getItem((
    <Link to="/report-time-table">Thời khoá biểu</Link>
  ), '3', <DesktopOutlined />),
  getItem('Báo cáo', 'sub1', <UserOutlined />, [
    getItem((<Link to="/report/attend-detail">Kết quả học</Link>), '4'),
  ])
];

// eslint-disable-next-line react/prop-types
const MainLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const navigate = useNavigate();

  const userName = JSON.parse(localStorage.getItem("user")).name

  const handleMenuItemClick = ({ key }) => {
    if (key === "logout"){
      localStorage.removeItem("accessToken")
      localStorage.removeItem("user")
      navigate("/login");
      window.location.reload()
    }
    else{
      setChangePasswordVisible(true);
    }
  };

  const handleCancelChangePassword = () => {
    setChangePasswordVisible(false);
  };
  
  const UserProfileMenu = (
    <Menu>
      <Menu.Item key="changePassword" onClick={handleMenuItemClick}>
        <SettingOutlined />
        <span className={styles.dropDownMenuText}> Đổi mật khẩu </span>
      </Menu.Item>
      <Menu.Item key="logout" onClick={handleMenuItemClick}>
        <LogoutOutlined />
        <span className={styles.dropDownMenuText} > Đăng xuất </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <ChangePassword visible={changePasswordVisible} onCancel={handleCancelChangePassword} />
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className={styles.logos}> <span>DayHocTot.Edu.Vn</span> </div>
        <Menu className={styles['left-side-menu']} theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} />
      </Sider>
      <Layout className="site-layout">
        <Header className={styles.layoutHeader}>
          <div className="user-profile">
            <Dropdown overlay={UserProfileMenu}>
              <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                <Avatar src={''} alt="User Avatar" icon={<UserOutlined />} />
                <span className={styles.userName}>{userName}</span>
              </a>
            </Dropdown>
          </div>
        </Header>
        <Content
          style={{
            margin: '0 16px',
          }}
        >
          <div
            className="site-layout-background"
            style={{
              padding: 24,
              minHeight: 360,
            }}
          >
            {children}
          </div>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
          }}
        >
          Tool của Đỗ Xuân Thành
        </Footer>
      </Layout>
    </Layout>
  );
}

export default MainLayout