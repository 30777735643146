import React from "react";

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";



import TutorClass from "./pages/tutorClass/TutorClass.component"
import MainLayout from "./layout/MainLayout.component"
import ClassMember from "./pages/member/Member.component"
import ClassDetail from "./pages/tutorClass/ClassDetail/ClassDetail.component";
import ReportAttendDetail from "./pages/report/attendDetail/ReportAttendDetail.component"
import TimeTable from "./pages/timeTable/TimeTable/TimeTable.component";
import ReportTimeTable from "./pages/timeTable/ReportTimeTable/ReportTimeTable.component"
import Home from "./pages/home/Home"
import Register from "./pages/auth/register/Register"
import ProtectedRoute from './util/ProtectedRoute';


import './App.css';

function App() {
  const accessToken = localStorage.getItem("accessToken");
  console.log("Nam hoang")
  console.log(accessToken)

  if (!accessToken) {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route exact path="/login" element={
            <Home></Home>
          }>
          </Route>
          <Route exact path="/register" element={
            <Register></Register>
          }>
          </Route>
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    )
  }
  return (
    <Router>
      <MainLayout>
        <Routes>
          <Route exact path="/" element={
            <ProtectedRoute>
              <TutorClass />
            </ProtectedRoute>
          } >
          </Route>
          <Route path="/class-detail/:classId" element={
            <ProtectedRoute>
              <ClassDetail />
            </ProtectedRoute>
          }></Route>
          <Route path="/member" element={
            <ProtectedRoute>
              <ClassMember />
            </ProtectedRoute>
          }>
          </Route>
          <Route path="/time-table" element={
            <ProtectedRoute>
              <TimeTable />
            </ProtectedRoute>
          }>
          </Route>
          <Route path="/report/attend-detail" element={
            <ProtectedRoute>
              <ReportAttendDetail />
            </ProtectedRoute>
          }>
          </Route>
          <Route path="/report-time-table" element={
            <ProtectedRoute>
              <ReportTimeTable />
            </ProtectedRoute>
          }>
          </Route>
        </Routes>
      </MainLayout>
    </Router>
  );
}

export default App;
