
import { message } from 'antd';

export const showSuccessMessage = (aMessage) => {
  message.success({
    content: aMessage,
    duration: 2
  });
}

export const showErrorMessage = (aMessage) => {
  message.error({
    content: aMessage,
    duration: 5
  });
}

export const formatDate = (date) => {
  var d = date,
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

export const calculateDaysDifference = (attendDate) => {
  const currentDate = new Date();
  const attendanceDate = new Date(attendDate);

  const timeDifference = currentDate.getTime() - attendanceDate.getTime();
  const daysDifference = timeDifference / (1000 * 3600 * 24);
  return daysDifference;
};

