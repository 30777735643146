
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { useNavigate } from 'react-router-dom';

const API_URL: string = "https://hocthemapi.vegiare.com";

const axiosInstance: AxiosInstance = axios.create({
  baseURL: API_URL,
  // Add any other default configurations here
});


axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = localStorage.getItem("accessToken");
    config.headers['Authorization'] = `Bearer ${token}`;
    config.headers['Content-Type'] = "application/json";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle 401 Unauthorized error
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized. Redirecting to login page.');
      localStorage.removeItem("accessToken")
      localStorage.removeItem("user") 
    }

    // Pass the error along
    return Promise.reject(error);
  }
);

export default axiosInstance;


export const getData = async (path, params?) => {
  try {
    if (!params || params === "" === params === undefined) {
      return await axiosInstance.get(`${path}`);
    }
    else {
      return await axiosInstance.get(`${path}?${params}`);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const downloadFile = async (path, params) => {
  const options = {
    responseType: 'blob',
  }
  
  try {
    if (!params) {
      return await axiosInstance.get(`${path}`, options as AxiosRequestConfig);
    }
    else {
      return await axiosInstance.get(`${path}?${params}`, options as AxiosRequestConfig);
    }
  } catch (err) {
    throw new Error(err);
  }
};


export const postData = async (path, data) => {
  try {
    const response = await axiosInstance.post(`${path}`, data);
    return response
  } catch (err) {
    console.error('An error occurred:', err);
    throw err
  }
};

